<template>
  <div id="school">
    <div class="row justify-content-center">
      <div class="col-12 col-md-6">
        <lenon-form-layout
          title=""
          submit-text="Top up"
          :show-loading="updatingForm"
          :show-reset="false"
          :show-submit="false"
          submit-button-name="update-school"
        >
          <template slot="form-header">
            <div class="d-flex justify-content-center mb-2">
              <div class="d-flex flex-column justify-content-start">
                <div class="text-center mb-2">
                  <b-avatar
                    size="30px"
                    :variant="schoolInfo.activated_sms_status?'light-success':'light-danger'"
                  >
                    <feather-icon
                      size="20px"
                      :icon="schoolInfo.activated_sms_status?'CheckCircleIcon':'XCircleIcon'"
                    />
                  </b-avatar>
                </div>
                <div
                  style="font-size: 15px;"
                >
                  <div
                    v-if="schoolInfo.activated_sms_status"
                    class="row justify-content-center"
                  >
                    <div class="col-md-8 text-center">
                      <div>
                        <b>SMS CREDITS: {{ smsBalance }}</b>
                      </div>
                      <small class="text-danger">NB: If SMS credits is negative, then your last sms cost more than your
                        balance and will be deducted when you top up.</small>
                    </div>
                    <div
                      v-if="amount"
                      class="col-md-8"
                    >
                      <b>TOTAL CREDITS: {{ credits }}</b>
                    </div>
                    <div class="col-md-8">
                      <lenon-input
                        v-model="amount"
                        name="amount"
                        type="number"
                        placeholder="Amount (GHS)"
                        :label="`Top Up Amount (<small class='text-danger'><b>Transaction charges may apply</b></small>)`"
                      />
                    </div>
                    <div class="col-md-8">

                      <lenon-button
                        variant="outline-primary"
                        label="Top Up"
                        class="float-right"
                        :loading="loading"
                        :disabled="!amount"
                        @onClick="topUpSms()"
                      />
                    </div>
                  </div>
                  <div
                    v-else
                    class="text-align: center; row justify-content-center"
                  >
                    <div class="col-md-8 text-center">
                      Your <strong>sms/payment</strong> account has not been activated, please
                      click on the button below to begin activation process.
                    </div>
                  </div>
                  <div v-if="schoolInfo.activated_sms_status">
                    <div class="row justify-content-center mt-2">
                      <div class="col-md-8">
                        <lenon-input
                          v-model="sms.sender_name"
                          name="sender_name"
                          placeholder="Sender Name"
                          label="Sender Name"
                        />
                      </div>
                                            <div class="col-md-8">
                                              <lenon-input
                                                v-model="sms.client_secret"
                                                name="secret"
                                                placeholder="Paste your secret"
                                                label="Secret(Enables fee payments, you can contact support)"
                                              />
                                            </div>
                      <div class="col-md-8">
                        <lenon-button
                          :disabled="!sms.sender_name"
                          class="float-right"
                          label="Save"
                          @onClick="showPasswordModal()"
                        />
                        <lenon-button
                          variant="outline-primary"
                          class="float-right mr-1"
                          label="Send a Test Notification"
                          @onClick="sendTestSms()"
                        />
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="text-center mt-2">
                      <lenon-button
                        label="Activate"
                        @onClick="activateSmsAccount()"
                      />
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </template>
        </lenon-form-layout>
      </div>
    </div>
    <lenon-modal
      title="Confirm Your Password"
      :show="passwordModalOpened"
      :hide-footer="!password"
      ok-text="Continue"
      :loading="savingCredentials"
      @onClose="passwordModalOpened=false"
      @onOk="saveClientCredentials()"
    >
      <lenon-input
        v-model="password"
        label="Password"
        type="password"
        rules="required"
        placeholder="Please enter your password"
      />
    </lenon-modal>
  </div>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'
import LenonFormLayout from '@/lenon/components/layouts/LenonFormLayout.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import { ACTIVATE_SMS_ACCOUNT_M, SAVE_HUBTEL_CLIENT_CREDENTIALS_M, SEND_TEST_SMS_M } from '@/graphql/mutations'
import showToast from '@/lenon/mixins/showToast'
import LenonButton from '@/lenon/components/LenonButton.vue'
import confirm from '@/lenon/mixins/confirm'
import LenonModal from '@/lenon/components/LenonModal.vue'
import { GET_SCHOOL_Q, GET_SMS_BALANCE_Q } from '@/graphql/queries'

export default {
  name: 'School',
  components: {
    LenonModal,
    LenonButton,
    LenonInput,
    LenonFormLayout,
    BAvatar,
  },
  mixins: [showToast, confirm],
  data() {
    return {
      password: null,
      sms: {
        client_id: null,
        client_secret: null,
        sender_name: null,
      },
      amount: null,
      price: 0.04,
      error: {},
      passwordModalOpened: false,
      alias: null,
      updatingForm: false,
      savingCredentials: false,
      loading: false,
    }
  },
  computed: {
    credits() {
      return +this.amount / this.price
    },
    user() {
      return this.$store.getters['auth/user']
    },
    smsBalance() {
      return this.$store.getters['auth/smsBalance']
    },
    schoolInfo() {
      return this.$store.getters['auth/school']
    },
  },
  watch: {
    schoolInfo() {
      this.updateFields()
    },
  },
  mounted() {
    this.updateFields()
    this.getSchool()
    this.getSmsBalance()
  },
  methods: {
    topUpSms() {
      this.loading = true
      this.$store.dispatch('auth/initiateTopUp', this.amount).then(res => {
        this.showSuccess('Top up initiated successfully')
        window.open(res.data.authorization_url, '_blank')
      }).catch(err => {
        this.showError('Failed to initiate top up')
      }).finally(() => {
        this.loading = false
      })
    },
    saveClientCredentials() {
      this.savingCredentials = true
      this.$apollo.mutate({
        mutation: SAVE_HUBTEL_CLIENT_CREDENTIALS_M,
        variables: { password: this.password, ...this.sms },
      })
        .then(res => {
          if (!res.errors && res.data.saveHubtelClientCredentials) {
            this.showSuccess('Updated Successfully')
            this.sms.client_id = null
            this.sms.client_secret = null
            this.passwordModalOpened = false
            this.password = null
          } else {
            this.showError('Failed to save credentials, make sure your password is correct.')
          }
        })
        .finally(() => {
          this.savingCredentials = false
        })
    },
    updateFields() {
      this.sms.sender_name = this.schoolInfo.alias
    },
    getSchool() {
      this.$apollo.query({ query: GET_SCHOOL_Q })
        .then(res => {
          this.$store.commit('auth/updateSchool', res.data.school)
        })
    },
    getSmsBalance() {
      this.$apollo.query({ query: GET_SMS_BALANCE_Q })
        .then(res => {
          this.$store.commit('auth/setSmsBalance', res.data.smsBalance)
        })
        .catch(err => {
          // this.showError('Failed to retrieve sms balance')
        })
    },
    onClose() {
      this.passwordModalOpened = false
    },
    activateSmsAccount() {
      this.confirmAction('Please confirm this action.')
        .then(res => {
          if (res) {
            this.updatingForm = true
            this.$apollo.mutate({
              mutation: ACTIVATE_SMS_ACCOUNT_M,
            })
              .then(res => {
                this.updatingForm = false
                this.showSuccess('Activation request has been sent successfully,'
                        + ' you will be contacted by a Lenon Rep to begin the process.')
                this.$store.commit('auth/updateSchool', { activated_sms_status: true })
              })
              .catch(err => {
                this.updatingForm = false
                this.showError('Something went wrong, please try again')
              })
          }
        })
    },
    showPasswordModal() {
      this.passwordModalOpened = true
    },
    sendTestSms() {
      this.confirmAction('When you confirm, a test SMS will be sent to your school\'s phone. This is good way to test if your configuration was setup correctly.', 'Confirm')
        .then(res => {
          if (res) {
            this.$apollo.mutate({
              mutation: SEND_TEST_SMS_M,
            })
              .then(res => {
                this.showSuccess('You will receive a test SMS shortly. If you do not, please contact support.')
              })
              .catch(err => {
                this.showError('Something went wrong, please try again')
              })
          }
        })
    },
  },
}
</script>
